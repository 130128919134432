import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
const isMobileDevice = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

export default function PDFViewerPage() {
  const { pdfUrl } = useParams();
  const decodedPdfUrl = decodeURIComponent(pdfUrl || "");

  useEffect(() => {
    // Open PDF in the same tab for mobile users
    if (isMobileDevice()) {
      window.location.href = decodedPdfUrl;
    }
  }, [decodedPdfUrl]);

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {isMobileDevice() ? (
        <p className="pt-6 text-center mx-auto max-w-7xl place-items-center">
          If the PDF doesn't open automatically, you can{" "}
          <a
            className="text-blue-600 text-xl"
            href={decodedPdfUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            click here to view or download the PDF.
          </a>
          <hr className="border-t-2 border-gray-600 my-4" />
          {/* return home */}
          <Link className="text-green-600 text-xl" to="/">
            Return home
          </Link>
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Chautari Homecare</span>
            <img
              className="h-32 w-auto"
              src={
                "https://api.chautarihomecare.com/wp-content/uploads/2023/09/logo2.png"
              }
              alt="Chautari Homecare logo"
              width={250}
              height={250}
            />
          </Link>
        </p>
      ) : (
        <embed
          src={decodedPdfUrl}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
}
