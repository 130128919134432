import { createBrowserRouter } from "react-router-dom";

import {
  HomePage,
  AboutPage,
  ServicesPage,
  CareersPage,
  EmployeeResourcesPage,
  ConsumerResourcesPage,
  PDFViewerPage,
} from "./pages";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "about",
    element: <AboutPage />,
  },
  {
    path: "services",
    element: <ServicesPage />,
  },
  {
    path: "careers",
    element: <CareersPage />,
  },
  {
    path: "employee-resources",
    element: <EmployeeResourcesPage />,
  },
  {
    path: "consumer-resources",
    element: <ConsumerResourcesPage />,
  },
  {
    path: "pdf-viewer/:pdfUrl",
    element: <PDFViewerPage />,
  },
]);
