import React from "react";
import { Layout } from "../components/common/Layout";

import {
  InboxArrowDownIcon,
  InformationCircleIcon,
  MapPinIcon,
  PhoneArrowDownLeftIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { Hero } from "../components/Hero";
import { CheckIcon } from "@heroicons/react/20/solid";
//import { NewsLetter } from "../components/common/NewsLetter";
type Tab = {
  id: number;
  name: string;
  href: string;
  current: boolean;
};
const tabs: Tab[] = [
  { id: 1, name: "About us", href: "#", current: true },
  { id: 2, name: "Mission and Vision", href: "#", current: false },
  { id: 3, name: "How to get started", href: "#", current: false },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function AboutPage() {
  const [activeTab, setActiveTab] = React.useState(tabs[0].id);
  const handleTabClick = function (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    tabId: number
  ) {
    e.preventDefault();
    setActiveTab(tabId);
  };

  return (
    <Layout>
      <Hero
        imageUrl="https://api.chautarihomecare.com/wp-content/uploads/2023/09/about_cover-scaled.jpg"
        title="About Us"
      />
      <div className="mx-auto max-w-7xl p-10">
        <div className="mx-auto max-w-6xl -mt-10 z-1">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              value={activeTab}
              onChange={(e) => setActiveTab(+e.target.value)}
            >
              {tabs.map((tab: Tab) => (
                <option key={tab.id}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200 bg-white">
              <nav className="-mb-px flex " aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={`#${tab.name}`}
                    className={classNames(
                      tab.id === activeTab
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "w-1/4 border-b-2 py-4 px-8 text-center text-xl font-medium"
                    )}
                    aria-current={activeTab === tab.id ? "page" : undefined}
                    onClick={(e) => handleTabClick(e, tab.id)}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>

        <div className="mt-4">
          {tabs.map((tab) => (
            <div
              key={tab.name}
              id={tab.name} // Use the same ID as the associated tab
              className={classNames(
                activeTab === tab.id ? "block" : "hidden",
                "py-2"
              )}
            >
              {tab.id === 1 && <Tab1Content />}
              {tab.id === 2 && <Tab2Content />}
              {tab.id === 3 && <Tab3Content />}
            </div>
          ))}
        </div>
        <div className="mt-4 px-4">
          <CollapsibleList />
        </div>
      </div>
      <div className="mt-4 bg-white">
        <AboutUsImageBanner />
      </div>
      {/* <div className="bg-white">
        <NewsLetter title="" />
      </div> */}
    </Layout>
  );
}

function Tab1Content() {
  return (
    <div>
      {/* Add two column layout */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Left column */}
        <div className="p-5 md:p-1">
          <p className="text-lg text-muted">
            Chautari Home care, nestled in the heart of Pennsylvania, is a
            compassionate provider of home care and staffing services. Our team
            comprises individuals of diverse expertise, each equipped with years
            of valuable experience and an unwavering commitment to enriching the
            lives of those we serve.
          </p>

          <ul className="list-none px-5 mt-8 text-justify">
            <li className="text-slate-500 hover:text-blue-600 font-medium text-lg mb-2">
              <p>
                Providing and receiving care can be a challenging journey. At
                Chautari Home care, we prioritize building meaningful
                relationships to assist caregivers, preserve the dignity of care
                recipients, and improve the overall well-being of both seniors
                and their families.
              </p>
            </li>
            <li className="text-slate-500 hover:text-blue-600 font-medium text-lg mb-2">
              <p>
                Chautari Home care is dedicated to delivering exceptional
                in-home care services around the clock, every day of the year,
                ensuring support is always available for those in need.
              </p>
            </li>
            <li className="text-slate-500 hover:text-blue-600 font-medium text-lg mb-2">
              <p>
                Our hearts swell with pride for our compassionate home care
                program, carefully crafted to empower individuals to maintain
                their independence and enjoy a healthier, more extended stay in
                the comfort of their own homes.
              </p>
            </li>
          </ul>
        </div>
        {/* Right column */}
        <div className="py-1">
          {/* Add four card list and style them using tailwind css */}
          <div className="grid grid-cols-1 gap-3">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg hover:border-b-2 hover:border-red-500">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-bold text-gray-900 ">
                  <CheckIcon className="inline w-5 h-5 text-green-600" />
                  &nbsp; Daily home care services
                </h3>
              </div>
            </div>

            <div className="bg-white shadow overflow-hidden sm:rounded-lg hover:border-b-2 hover:border-red-500">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-bold text-gray-900">
                  <CheckIcon className="inline w-5 h-5 text-green-600" />
                  &nbsp; Hourly home care services
                </h3>
              </div>
            </div>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg hover:border-b-2 hover:border-red-500">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-bold text-gray-900">
                  <CheckIcon className="inline w-5 h-5 text-green-600" />
                  &nbsp; Respite home care services
                </h3>
              </div>
            </div>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg hover:border-b-2 hover:border-red-500">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-bold text-gray-900">
                  <CheckIcon className="inline w-5 h-5 text-green-600" />
                  &nbsp; Specialized home care services
                </h3>
              </div>
            </div>
          </div>
          <div className="py-4 mt-2">
            <h3 className="text-lg leading-6 font-bold text-gray-900 mb-2">
              Pittsburgh Office
            </h3>
            <ul className="space-y-3">
              <li className="flex items-center font-medium">
                <MapPinIcon className="w-5 h-5 mr-2 text-green-600" />
                5624 Brownsville Rd, Pittsburgh, PA 15236
              </li>

              <li className="flex items-center font-medium">
                <EnvelopeIcon className="w-5 h-5 mr-2 text-green-600" />
                contact@chautarihomecare.com
              </li>
              <li className="flex items-center font-medium">
                <PhoneArrowDownLeftIcon className="w-5 h-5 mr-2 text-green-600" />
                412-884-2046 Ext. 1
              </li>
              <li className="flex items-center font-medium">
                <InboxArrowDownIcon className="w-5 h-5 mr-2 text-green-600" />
                412-206-2971
              </li>
            </ul>
          </div>
          <div className="py-1 mt-1">
            <h3 className="text-lg leading-6 font-bold text-gray-900 mb-2">
              Harrisburg Office
            </h3>
            <ul className="space-y-3">
              <li className="flex items-center font-medium">
                <MapPinIcon className="w-5 h-5 mr-2 text-green-600" />
                4601 Locust Lane, Suite 101, Harrisburg, PA 17109
              </li>
              <li className="flex items-center font-medium">
                <EnvelopeIcon className="w-5 h-5 mr-2 text-green-600" />
                hbginfo@chautarihomecare.com
              </li>
              <li className="flex items-center font-medium">
                <PhoneArrowDownLeftIcon className="w-5 h-5 mr-2 text-green-600" />
                +1 412-884-2046 Ext. 2
              </li>
              <li className="flex items-center font-medium">
                <InboxArrowDownIcon className="w-5 h-5 mr-2 text-green-600" />
                412-206-2971
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function Tab2Content() {
  return (
    <div className="px-6 py-3">
      <p className="py-4 text-slate-600 text-justify">
        At Chautari Home Care, our unwavering dedication is to deliver top-tier
        healthcare services to individuals and their families. Our foremost
        objective is to furnish cutting-edge, compassionate in-home care through
        a team of exceptionally skilled professionals and aides. In keeping with
        this commitment, comprehensive training takes precedence. We don&apos;t
        just recruit the most seasoned personnel; we also equip them with
        in-depth, disease specific training to ensure they can provide
        specialized care tailored to the unique needs of our clients.
      </p>

      <p className="py-4 text-slate-600 text-justify">
        Chautari Home Care holds a fundamental belief that every individual
        deserves the chance to lead a vibrant, healthy, and satisfying life.
        This principle forms the core of our mission. Our commitment is to
        empower each person we assist to embrace life to the fullest extent
        possible. We actively promote self-reliance among our clients,
        encouraging them to take charge of their decisions and, when ready,
        transition towards more independent living situations. We are dedicated
        to facilitating and nurturing the pursuit of meaningful and personalized
        lifestyles for our Service Users. Moreover, we offer tailored education
        and leisure opportunities designed to support each individual&apos;s
        continuous growth and development.
      </p>
    </div>
  );
}

function Tab3Content() {
  type Step = {
    id: string;
    name: string;
    description: string;
    href: string;
    status: string;
  };
  const steps: Step[] = [
    {
      id: "Step 1",
      name: "Call to recieve advice",
      description:
        "Call and speak to one of our friendly team who will discuss the application process and set a schedule for checkups.",
      href: "#",
      status: "current",
    },
    {
      id: "Step 2",
      name: "Request a plan",
      description:
        "Request a home visit where we will provide you with a thorough checkups, deep analysis and detailed plan.",
      href: "#",
      status: "upcoming",
    },
    {
      id: "Step 3",
      name: "Preview and Select your care package",
      description:
        "Once agreed, we will start your care package and introduce you to your caregiver as soon as possible.",
      href: "#",
      status: "upcoming",
    },
  ];
  return (
    <div className="px-6 py-3 mt-3">
      <nav aria-label="Progress">
        <ol className="space-y-4 md:flex md:space-x-8 md:space-y-0">
          {steps.map((step) => (
            <li key={step.id} className="md:flex-1">
              {step.status === "complete" ? (
                <a
                  href={step.href}
                  className="group flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                >
                  <span className="text-xl font-medium text-indigo-600 group-hover:text-indigo-800">
                    {step.id}
                  </span>
                  <span className="text-lg font-medium">{step.name}</span>
                  <p>{step.description}</p>
                </a>
              ) : step.status === "current" ? (
                <a
                  href={step.href}
                  className="flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                  aria-current="step"
                >
                  <span className="text-xl font-medium text-indigo-600">
                    {step.id}
                  </span>
                  <span className="text-lg font-medium">{step.name}</span>
                  <p>{step.description}</p>
                </a>
              ) : (
                <a
                  href={step.href}
                  className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                >
                  <span className="text-xl font-medium text-gray-500 group-hover:text-gray-700">
                    {step.id}
                  </span>
                  <span className="text-lg font-medium">{step.name}</span>
                  <p>{step.description}</p>
                </a>
              )}
            </li>
          ))}
        </ol>
        <div className="mx-auto text-center mt-5 py-4">
          <button
            disabled={true}
            className="rounded-md bg-blue-500 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Request an appointment
          </button>
        </div>
      </nav>
    </div>
  );
}

function CollapsibleList() {
  const [isOpen1, setIsOpen1] = React.useState(true);
  const [isOpen2, setIsOpen2] = React.useState(false);

  const toggleItem = (itemNumber: number) => {
    if (itemNumber === 1) {
      setIsOpen1(!isOpen1);
    } else if (itemNumber === 2) {
      setIsOpen2(!isOpen2);
    }
  };

  return (
    <ul className="space-y-2">
      <li className="relative mb-3" onClick={() => toggleItem(1)}>
        <div className="flex items-center justify-between cursor-pointer text-slate-500 hover:text-blue-600 bg-sky-950 py-2 px-3 ">
          <h3 className="text-white text-lg font-bold">What we offer</h3>
          <button
            className={`icon-btn ${isOpen1 ? "active" : ""} text-white text-xl`}
            onClick={() => toggleItem(1)}
          >
            {isOpen1 ? "-" : "+"}
          </button>
        </div>
        {isOpen1 && (
          <div className="py-3 px-4 bg-slate-300">
            <p className="content bg-light-300 text-justify">
              By offering inventive solutions that enhance the well-being and
              quality of life for individuals requiring in-home healthcare
              services, Chautari Home Care has solidified its position as one of
              Pittsburg&apos;s most seasoned home care providers. Our dedication
              to delivering high-quality care revolves around a commitment to
              unparalleled expertise, comprehensive training, exceptional
              patient service, and rigorous monitoring, setting a standard that
              is challenging to rival within our field.
            </p>
          </div>
        )}
      </li>
      <li className="relative" onClick={() => toggleItem(2)}>
        <div className="flex items-center justify-between cursor-pointer text-slate-500 hover:text-blue-600 bg-sky-950 py-2 px-3 ">
          <h3 className="text-white text-lg font-bold">How we work</h3>
          <button
            className={`icon-btn ${isOpen2 ? "active" : ""} text-white text-xl`}
            onClick={() => toggleItem(2)}
          >
            {isOpen2 ? "-" : "+"}
          </button>
        </div>
        {isOpen2 && (
          <div className="py-3 px-4 bg-slate-300">
            <p className="content text-justify">
              Eldercare, often referred to as senior care or aged care, involves
              meeting the distinct and specific needs of senior citizens. Given
              the diverse landscape of elderly care across different regions and
              varying perspectives on caring for the elderly, there is no
              one-size-fits-all approach. Our expertise lies in providing
              in-home caregiving services, where our highly trained and
              up-to-date professional staff are dedicated to delivering the
              utmost care and support to our elderly clients.
            </p>
          </div>
        )}
      </li>
    </ul>
  );
}

function AboutUsImageBanner() {
  return (
    <div className="relative">
      <div className="mx-auto max-w-7xl">
        <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
          <svg
            className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="0,0 90,0 50,100 0,100" />
          </svg>

          <div className="relative px-6 py-16 sm:py-40 lg:px-8 lg:py-20 lg:pr-0">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Why us ?
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
                Choose Chautari Home care Agency for unparalleled in-home care
                services. Experience the difference with Chautari, where
                excellence and compassion come together for a brighter,
                healthier future for your loved ones.
              </p>
              <ul className="space-y-4 mt-6">
                <li className="flex items-center text-xl text-black-500">
                  <InformationCircleIcon className="w-10 h-10 mr-2 text-blue-600" />
                  We are a locally owned and operated leading home health agency
                  in the state of Pennsylvania.
                </li>
                <li className="flex items-center text-xl text-black-500">
                  <InformationCircleIcon className="w-10 h-10 mr-2 text-blue-600" />
                  Our staff members are trained and qualified who are dedicated
                  in developing your personalized care plan and offer timely
                  services.
                </li>
                <li className="flex items-center text-xl text-black-500">
                  <InformationCircleIcon className="w-10 h-10 mr-2 text-blue-600" />
                  We are committed to providing compassionate care and your
                  safety is our priority.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
          src="https://images.unsplash.com/photo-1422015347944-9dd46d16bd0b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80"
          alt=""
        />
      </div>
    </div>
  );
}
